import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/prof/prof/deps/docs/src/components/DocsLayout.jsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Smallstep Registration Authorities (RA) provide automated enrollment and renewal of certificates using modern techniques while extending your existing internal PKI. Smallstep RAs act narrowly as a `}<em parentName="p">{`registration authority`}</em>{`, accepting certificate orders, and authenticating certificate requests. Smallstep RAs do not sign certificates. Instead, certificate requests are passed to your existing PKI infrastructure to sign and catalog. Benefits of this approach include:`}</p>
    <ul>
      <li parentName="ul">{`Issued certificates are trusted by anything that trusts your PKI root certificate.`}</li>
      <li parentName="ul">{`Issued certificates appear in your PKI console and audit logs.`}</li>
      <li parentName="ul">{`Security-sensitive signing keys are managed by your existing PKI and never seen by Smallstep ACME RA.`}</li>
    </ul>
    <p><img parentName="p" {...{
        "src": "/graphics/docs-registration-authorities-logo.png",
        "alt": "Smallstep RA",
        "title": "Smallstep RA"
      }}></img></p>
    <p>{`Today Smallstep is focused on building ACME registration authorities for popular PKI platforms. In the future, we intend to expand our RA support to address the capabilities of the many Smallstep provisioners. `}</p>
    <ul>
      <li parentName="ul">{`Try it today on GCP with the `}<a parentName="li" {...{
          "href": "https:/prof.infra.smallstep.com/docs/registration-authorities/acme-for-cas"
        }}>{`ACME RA for Google Certificate Authority Service`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://info.smallstep.com/acme-protocol/"
        }}>{`Register for early access`}</a>{` for other PKI providers (Microsoft ADCS, HashiCorp Vault, AWS ACM PCA, EJBCA, and others)`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      